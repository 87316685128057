.App {
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #056281;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-card {
  position: relative;
  border: 2px solid white;
  border-radius: 5px;
  width: 500px;
  overflow: hidden;
}

.info-card h1 {
  text-align: center;
  justify-content: center;
  color: white;
  display: flex;
  gap: 10px;
}

.shinning-box {
  transform: rotate(-40deg);
  top: 15px;
  position: absolute;
  width: 180px;
  height: 50px;
  background-color:rgb(201, 200, 181);
  opacity: 0.2;
  animation: shine 2s ease-in infinite;
}

@keyframes shine {
  from {left: -180px;}
  to {left: 550px;}
}

@media only screen and (max-width:600px) {
  .info-card h1 {
    font-size: 20px;
  }

  .info-card {
    
    width: 300px;
  }

}